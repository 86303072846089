import React, { useEffect } from "react";
import { Link } from "react-scroll";
import "../SeaFrieght/SeaFrieght.css";
import SeaFreightImg from "../../../assets/SeaHomeImg.png";
import image1 from "../../../assets/Image 1.png";
import image2 from "../../../assets/Image 2.png";
import image3 from "../../../assets/Image 3.png";
import image4 from "../../../assets/Image 4.png";
import SeaService from "./SeaService";

const SeaFrieght = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="seaFrieght-container">
        <div className="whole-sea-container">
          <div className="seaFrieght-Left" data-aos="fade-up">
            <h5>Let’s shift your business</h5>
            <h3>Your Gateway to Seamless Sea Freight Solutions</h3>
            <p>
              Embark on a journey of global trade with confidence, powered by
              Airsteam Logistics' comprehensive suite of sea freight services.
            </p>
            <Link
              to="seaFrieght-Img-container"
              smooth={true}
              offset={-180}
              duration={2000}
              href="#about-down"
            >
              <button className="btn-Home" data-aos="zoom-in-up" type="">
                Let's get started!
              </button>
            </Link>
          </div>
          <div className="seaFrieght-Right" data-aos="zoom-in">
            <img src={SeaFreightImg} alt="" />
          </div>
        </div>
      </div>
      <div className="whole-sea-container">
        <div className="seaFrieght-Left" data-aos="slide-right">
          <h2 style={{ fontSize: "35px", lineHeight: 1.5 }}>
            We are here to help the customers to get their success.
          </h2>
        </div>
        <div className="seaFrieght-Right">
          <p data-aos="fade-up">
            At Airsteam Logistics, we empower our customers to achieve success
            through efficient and reliable logistics. Our services streamline
            supply chains, reduce costs, and ensure timely deliveries.
          </p>
          <p data-aos="fade-up">
            Committed to excellence and leveraging cutting-edge technology, we
            provide tailored solutions for businesses of all sizes. Our team
            supports you every step of the way, addressing logistical challenges
            with innovation and expertise.
          </p>
        </div>
      </div>
      <div className="seaFrieght-Img-container">
        <div className="images">
          <img
            src={image1}
            data-aos="fade-up-right"
            alt="sea"
            className="image"
          />
          <img
            src={image2}
            data-aos="fade-up-left"
            alt="sea"
            className="image"
          />
          <img
            src={image3}
            data-aos="fade-up-right"
            alt="sea"
            className="image"
          />
          <img
            src={image4}
            data-aos="fade-up-left"
            alt="sea"
            className="image"
          />
        </div>
        <div className="text-content">
          <h2 data-aos="fade-up">Sea Freight Solutions.</h2>
          <p data-aos="fade-up">
            Embark on a journey of global trade with confidence, powered by
            Airsteam Logistics' comprehensive sea freight services. Our
            expertise ensures your goods reach their destination safely and on
            time, navigating the complexities of international shipping with
            precision.
          </p>
          <p data-aos="fade-up">
            From our headquarters to seaports worldwide, Airsteam Logistics
            connects businesses across continents. Our extensive network and
            state-of-the-art facilities provide seamless service from origin to
            destination. Whether shipping bulk commodities, specialized
            equipment, or perishable goods, our tailored solutions meet your
            unique requirements with advanced technology and real-time tracking.
          </p>
          <p data-aos="fade-up">
            Efficiency and reliability are at the forefront of our operations.
            We optimize shipping schedules, reduce transit times, and minimize
            costs while maintaining high service standards. Trust Airsteam
            Logistics to handle your sea freight needs with care and expertise,
            so you can focus on growing your business and reaching new markets
            confidently.
          </p>
        </div>
      </div>
      <SeaService />
      <div className="paragh" data-aos="fade-up">
        <p>
          Recognizing that every cargo presents its distinct requirements and
          hurdles, we transcend a generic approach, providing customized
          solutions tailored precisely to your needs.
        </p>
      </div>
    </>
  );
};

export default SeaFrieght;
