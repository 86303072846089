import React, { useState } from "react";
import WhatsApp from "../../../assets/WhatsApp.svg";
import Messages from "../../../assets/Messages.png";
import { Link } from "react-router-dom";
import "./Chats.css";

const WhatsAppChat = () => {
  const [showWhatsAppNotification, setShowWhatsAppNotification] =
    useState(true);

  const handleWhatsAppClick = () => {
    setShowWhatsAppNotification(false);
  };

  return (
    <div className="Whatsapp">
      <a
        href="https://wa.me/9778725551?text=Hello%20I%20need%20some%20help"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleWhatsAppClick}
      >
        <div className="chat-icon-container">
          <img src={WhatsApp} alt="WhatsApp Chat" className="chat-icon" />
          {showWhatsAppNotification && (
            <div className="notification-dot">1</div>
          )}
        </div>
      </a>
    </div>
  );
};

export const MessageChat = () => {
  const [showMessagesNotification, setShowMessagesNotification] =
    useState(true);

  const handleMessagesClick = () => {
    setShowMessagesNotification(false);
  };

  return (
    <div className="Message">
      <Link to="/ContactUs" onClick={handleMessagesClick}>
        <div className="chat-icon-container">
          <img src={Messages} alt="Messages Chat" className="chat-icon" />
          {showMessagesNotification && (
            <div className="notification-dot">1</div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default WhatsAppChat;
