import React, { useEffect } from "react";
import "../DGShipping/DGShipping.css";
import DGShippingImg1 from "../../../assets/Img 1.png";
import DGShippingImg2 from "../../../assets/Img 2.png";
import DGShippingService from "./DGShippingService";

const DGShipping = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="talented-people">
        <div className="text-container">
          <h1 data-aos="slide-up">
            Specialized DG Shipping Solutions with Airsteam.
          </h1>
          <p data-aos="fade-up">
            Entrust your hazardous cargo to the experts. With our specialized
            expertise in shipping dangerous goods, we prioritize safety and
            security at every stage of the journey.
          </p>
        </div>
        <div className="DGimage-container">
          <div className="DGimage-card" data-aos="fade-left">
            <img src={DGShippingImg1} alt="Talented person 1" />
          </div>
          <div className="DGimage-card" data-aos="fade-right">
            <img src={DGShippingImg2} alt="Talented person 2" />
          </div>
        </div>
      </div>
      <DGShippingService />
    </>
  );
};

export default DGShipping;
