import React, { useEffect } from "react";
import Video from "../HomeVideo/Video";
import Aboutus from "../../Aboutus/Aboutus";
import Service from "../HomeService/ServiceHome";
import ServicesTwo from "../ServicesSecond/ServicesTwo";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Home">
      <Video />
      <Aboutus />
      <ServicesTwo />
      <Service />
    </div>
  );
};

export default Home;
