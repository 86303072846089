import React, { useEffect } from "react";
import { Link } from "react-scroll";
import "../WareHousing/WareHousing.css";
import LabellingImg from "../../../assets/CustomClearence.png";
import CustomFeatures from "./CustomFeature";

const CustomClearencce = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="WareHouse-container">
        <div className="WareHose-Right" data-aos="fade-up">
          <h5>Let’s shift your business</h5>
          <h3>Your Trusted Partner for Effortless Custom Clearance</h3>
          <p>
            Embark on a journey of global trade with confidence, powered by
            Airsteam Logistics' comprehensive suite of Custom Clearence.
          </p>
          <Link
            to="feat-content"
            smooth={true}
            offset={-180}
            duration={2000}
            href="#about-down"
          >
            <button
              className="btn-Home"
              data-aos="zoom-in-up"
              style={{ marginTop: "10%" }}
              type=""
            >
              Let's get started!
            </button>
          </Link>
        </div>
        <div className="WareHose-left" data-aos="fade-left">
          <img
            src={LabellingImg}
            style={{ width: "350px" }}
            alt="Warehouse"
            className="left-image"
          />
        </div>
      </div>
      <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <CustomFeatures />
      </div>
    </>
  );
};

export default CustomClearencce;
