import React, { useEffect } from "react";
import "./AirFrieght.css";
import AirFrieghtImg1 from "../../../assets/1.png";
import AirFrieghtImg2 from "../../../assets/2.png";
import AirFrieghtImg3 from "../../../assets/3.png";
import AirFreightService from "./AirFreightService";

const AirFreight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="air-freight">
        <div className="air-freight-text" data-aos="slide-up">
          <h1>Effortless Air Freight Solutions for Your Global Cargo Needs</h1>
        </div>
        <div className="image-container">
          <div data-aos="fade-right" className="image-card">
            <img src={AirFrieghtImg1} alt="" />
          </div>
          <div data-aos="fade-up" className="image-card">
            <img src={AirFrieghtImg2} alt="" />
          </div>
          <div data-aos="fade-left" className="image-card">
            <img src={AirFrieghtImg3} alt="" />
          </div>
        </div>
        <div className="footer-text">
          <p data-aos="fade-up">
            Count on us for impeccable air freight solutions, connecting your
            cargo to key destinations worldwide. Our experience ensures timely
            and efficient handling of shipments. Advanced tracking keeps you
            informed, providing transparency and peace of mind.
          </p>
          <p data-aos="fade-up">
            With partnerships with top airlines and carriers, we offer reliable
            deliveries, competitive rates, and flexible scheduling. Whether
            shipping electronics, perishables, or urgent documents, our
            customized solutions meet your business needs. Trust us for
            excellence in air freight logistics, so you can focus on growing
            your business.
          </p>
        </div>
      </div>
      <AirFreightService />
      <div className="paragh">
        <p data-aos="fade-up">
          With our IATA agency services in Cochin, we guarantee prompt
          collection and dispatch from all airport cargo handling facilities.
          Our commitment ensures smooth import consolidation and efficient
          back-to-air shipments for exports, facilitating uninterrupted global
          trade. Let us connect your cargo to the world while you focus on
          propelling your business forward.
        </p>
      </div>
    </>
  );
};

export default AirFreight;
