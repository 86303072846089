import React from "react";
import "./Aboutus.css";
import Aboutimg from "../../assets/AboutImg.jpg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <div className="about">
      <div className="about-right" data-aos="zoom-in-up">
        <img src={Aboutimg} className="about-img" alt="" />
      </div>
      <div className="about-left">
        <div className="line"></div>
        <div className="about-heading">
          <h3>Our Story</h3>
        </div>

        <p>
          At our logistics company,we pride ourselves on our progressive
          approach to{" "}
          <span className="about-content">supply chain management. </span> We
          leverage advanced technologies and strategic partnerships to optimize
          warehousing, transportation, inventory management, and distribution.
          Our commitment to efficiency and reliability ensures that we deliver
          high-quality service at competitive costs.
        </p>
        <p className="moreinfo">
          <Link className="a-link" to="/OurStory">
            Read More
            <FontAwesomeIcon className="Rightarrow" icon={faArrowRight} />
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Aboutus;
