import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [result, setResult] = useState("");

  const notifySuccess = () => toast.success("File Submitted Successfully!");
  const notifySending = () => toast.warn("Submitting...");

  const handleSubmit = async (event) => {
    event.preventDefault();
    notifySending();

    const formData = new FormData(event.target);
    formData.append("access_key", "2672913d-85e1-4165-8be9-3476ebcb72c5");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        event.target.reset();
        setResult("");
        notifySuccess();
      } else {
        setResult(data.message);
      }
    } catch (error) {
      setResult("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      const elements = document.querySelectorAll("[data-aos='fade-up-left']");

      elements.forEach((element) => {
        if (isMobile) {
          element.removeAttribute("data-aos");
        } else {
          element.setAttribute("data-aos", "fade-up-left");
        }
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="contact">
      <div className="contact-col">
        <form id="Form-Fields" onSubmit={handleSubmit}>
          <h2>Send a message</h2>
          <div className="input-group">
            <div className="input-row">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                required
                autoComplete="name"
              />
            </div>
            <div className="input-row">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
                autoComplete="email"
              />
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="tel">Phone Number</label>
            <input
              type="tel"
              id="tel"
              name="phone"
              placeholder="Enter your mobile number"
              required
              autoComplete="tel"
            />
          </div>
          <div className="input-group">
            <label htmlFor="message">Write Your message here</label>
            <textarea
              name="message"
              id="message"
              rows="6"
              placeholder="Enter your message"
              required
            ></textarea>
          </div>
          <div className="cont-btn">
            <button
              type="submit"
              style={{ marginTop: "0px", paddingRight: "30px" }}
              className="btn-Home"
            >
              Submit
            </button>
          </div>
          {result && <span className="error">{result}</span>}
          <ToastContainer />
        </form>
      </div>
      <div className="contact-col">
        <iframe
          src="https://www.google.com/maps/d/u/0/embed?mid=1EeMEfNkSY6QoJxda4pORi8hC__9Z6jY&ehbc=2E312F"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          title="Google Maps Location"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
