import React from "react";
import "./ForkLiftCard.css";
import ForkLiftImage from "../../../assets/Floak.png";
const ForkLiftCard = () => {
  return (
    <div className="logistics-container">
      <h1 data-aos="slide-up">
        Comprehensive Warehousing & Distribution Services
      </h1>
      <p data-aos="fade-up">
        We can manage end to end Logistics helps its entrepreneurs connect with
        mentors and associates in the global network through a targeted and
        needs-based approach. Connections include mentoring.
      </p>
      <p data-aos="fade-up">
        We prioritise quality. Our GDP-certified temperature-controlled
        warehouses uphold the highest standards, ensuring optimal storage
        conditions for your goods, whether perishable or sensitive. Let us
        empower your business success with our unmatched expertise and
        commitment to excellence.
      </p>
      <div className="logistics-content">
        <div className="left-side">
          <div className="logistics-image" data-aos="zoom-in-left">
            <img src={ForkLiftImage} alt="Forklift" />
          </div>
          <div className="logistics-items">
            <div className="logistics-item" data-aos="zoom-in-right">
              <div className="logistics-number">01</div>
              <div>
                <h2>Short-Term and Long-Term Storage</h2>
                <p>
                  Flexible storage options customized to your unique
                  requirements, ensuring seamless scalability and
                  cost-effectiveness.
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="zoom-in-left">
              <div className="logistics-number">02</div>
              <div>
                <h2>Inventory Management</h2>
                <p>
                  Harness the power of efficient inventory control and
                  management, facilitated by our advanced tracking systems and
                  experienced team.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side">
          <div className="logistics-items-rightside">
            <div className="logistics-item" data-aos="slide-up">
              <div className="logistics-number">03</div>
              <div>
                <h2>Container Stuffing & De-stuffing</h2>
                <p>
                  Entrust your container operations to our skilled
                  professionals, guaranteeing smooth and secure handling every
                  step of the way.
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="zoom-in-left">
              <div className="logistics-number">04</div>
              <div>
                <h2>Pick and Pack Operations</h2>
                <p>
                  Streamline your order fulfillment process with our precision
                  pick and pack services, enhancing efficiency and customer
                  satisfaction.
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="zoom-in-right">
              <div className="logistics-number">05</div>
              <div>
                <h2>Scheduled Departures and Arrivals</h2>
                <p>
                  Benefit from our precise coordination of shipments, ensuring
                  timely departures and arrivals to meet your business demands.
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="fade-up">
              <div className="logistics-number">06</div>
              <div>
                <h2>Pick-Up and Delivery Services</h2>
                <p>
                  Rely on our dependable transportation services for hassle-free
                  pick-up and delivery, providing convenience and peace of mind.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForkLiftCard;
