import React from "react";
import "./ServicesTwo.css";
import FirstImg from "../../../assets/Rectangle 17.jpg";
import SecondImg from "../../../assets/Rectangle 18.jpg";
import ThirdImg from "../../../assets/Rectangle 19.jpg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000,
});

const ServicesTwo = () => {
  return (
    <div className="business-container">
      <div className="section">
        <div className="left" data-aos="fade-up">
          <h2>Sea Freight</h2>
          <h4>Sea freight logistics and container shipping solutions</h4>
          <p>
            Our expansive network links you to major ports worldwide, offering
            hassle-free customs clearance and dependable vessel schedules. Reach
            out to us to experience the difference.
          </p>
          <p className="moreinfo">
            <Link className="a-link" to="/Sea-Frieght">
              Learn More
              <FontAwesomeIcon className="Rightarrow" icon={faArrowRight} />
            </Link>
          </p>
        </div>
        <div
          className="right"
          data-aos="fade-right"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={FirstImg} alt="First " />
        </div>
      </div>
      <div className="section">
        <div className="left" data-aos="fade-right">
          <img src={SecondImg} alt="Second " />
        </div>
        <div className="right" data-aos="fade-up">
          <h2>Air Freight</h2>
          <h4>Air Logistics - Air Freight Shipping Services</h4>
          <p>
            We serve as your dependable ally, offering smooth airfreight
            solutions and efficient services that seamlessly connect your cargo
            across the globe. Entrust us with the safety of your shipment and
            benefit from competitive rates.
          </p>
          <p className="moreinfo">
            <Link className="a-link" to="/Air-Frieght">
              Learn More
              <FontAwesomeIcon className="Rightarrow" icon={faArrowRight} />
            </Link>
          </p>
        </div>
      </div>
      <div className="section">
        <div className="left" data-aos="fade-up">
          <h2>DG Shipping</h2>
          <h4>Handling Dangerous Goods with Expertise</h4>
          <p>
            Dangerous goods require meticulous handling due to their hazardous
            properties. Our team ensures compliance with all safety regulations,
            providing secure and efficient transport for your hazardous
            materials. Trust us to handle your dangerous goods safely and
            responsibly.
          </p>
          <p className="moreinfo">
            <Link className="a-link" to="/DG-Shipping">
              Learn More
              <FontAwesomeIcon className="Rightarrow" icon={faArrowRight} />
            </Link>
          </p>
        </div>
        <div
          className="right"
          data-aos="fade-right"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={ThirdImg} alt="Third" />
        </div>
      </div>
    </div>
  );
};

export default ServicesTwo;
