import React, { useEffect } from "react";
import { Link } from "react-scroll";
import "../WareHousing/WareHousing.css";
import LabellingImg from "../../../assets/Group 30.png";
import LabellingCard from "./LabellingCard";

const Labelling = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="WareHouse-container">
        <div className="WareHose-Right" data-aos="fade-up">
          <h5>Let’s shift your business</h5>
          <h3>Your Gateway to Seamless Labelling Solutions</h3>
          <p>
            Embark on a journey of global trade with confidence, powered by
            Airsteam Logistics' comprehensive suite of Labelling services.
          </p>
          <Link
            to="logistics-container"
            smooth={true}
            offset={-180}
            duration={2000}
            href="#about-down"
          >
            <button className="btn-Home" style={{ marginTop: "10%" }} type="">
              Let's get started!
            </button>
          </Link>
        </div>
        <div className="WareHose-left" data-aos="zoom-in-up">
          <img
            src={LabellingImg}
            style={{ width: "350px" }}
            alt="Warehouse"
            className="left-image"
          />
        </div>
      </div>
      <LabellingCard />
    </>
  );
};

export default Labelling;
