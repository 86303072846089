import React from "react";
import "../OurStory/KeyExpertise.css";
import { SeaFrieghtService } from "../OurStory/KeyData";

const Card = ({ title, content, icon, animation }) => {
  return (
    <div className="cards" data-aos={animation}>
      <div id="icon-container">
        <i id="icon" className={icon}></i>
      </div>
      <div className="user-info">
        <h2 className="card-title">{title}</h2>
        <p className="card-content">{content}</p>
      </div>
    </div>
  );
};

const SeaService = () => {
  return (
    <>
      <div className="key-heading" data-aos="zoom-in-up">
        <h3>Our Range of Sea Freight Service</h3>
      </div>
      <div className="card-container">
        {SeaFrieghtService.map((card, index) => (
          <Card
            key={index}
            icon={card.icon}
            title={card.title}
            content={card.content}
            animation={card.animation}
          />
        ))}
      </div>
    </>
  );
};

export default SeaService;
