import React, { useEffect, useState } from "react";
import videoSrc from "../../../assets/HomeVideo.mov";
import "./Video.css";

const Video = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const handleLoadedData = () => {
      setVideoLoaded(true);
    };

    const videoElement = document.getElementById("background-video");
    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleLoadedData);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleLoadedData);
      }
    };
  }, []);

  return (
    <div className="video-container">
      <video
        id="background-video"
        className={`video-background ${videoLoaded ? "show" : "hide"}`}
        autoPlay
        muted
        loop
        src={videoSrc}
      />
      <div className={`video-overlay ${videoLoaded ? "show" : "hide"}`}>
        <h1>Large Enough to Handle,</h1>
        <h1>Small Enough to Care</h1>
      </div>
    </div>
  );
};

export default Video;
