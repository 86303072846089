import React from "react";
import "../HomeService/ServiceHome.css";
import { Link } from "react-router-dom";
import ButtonComponent from "../../Button/Button";

const Service = () => {
  return (
    <div className="service-container">
      <section className="service-section">
        <div className="heading">
          <h2>Find the right partnership for your business</h2>
        </div>
        <div className="row">
          <div className="card" data-aos="fade-right">
            <div className="dropdown__icon">
              <i className="ri-home-5-fill"></i>
            </div>
            <h2>WareHousing</h2>
            <p>
              We specialize in providing warehouse solutions tailored to meet
              your specific needs and expectations.
            </p>
            <div className="styles_divider__line"></div>
            <p>
              Warehousing stores goods in a dedicated facility, enabling
              inventory management, product availability, and streamlined
              distribution.
            </p>
            <div className="ul-element">
              <ul>
                <li>Centralized inventory management.</li>
                <li>Improved supply chain efficiency</li>
                <li>Mitigation of supply chain risks.</li>
              </ul>
            </div>
            <Link className="a-link" to="/WareHousing">
              <ButtonComponent label={"Find More"} />
            </Link>
          </div>
          <div className="card" data-aos="fade-up">
            <div className="dropdown__icon">
              <i className="ri-price-tag-3-line"></i>
            </div>
            <h2>Labelling</h2>
            <p>
              We specialize in providing Labelling solutions tailored to meet
              your specific needs and expectations.
            </p>
            <div className="styles_divider__line"></div>
            <p>
              Labeling involves creating and attaching informative tags to
              products, providing essential details for identification,
              compliance, and consumer information.
            </p>
            <div className="ul-element">
              <ul>
                <li>Enhances product identification.</li>
                <li>Ensures regulatory compliance.</li>
                <li>Provides essential consumer information.</li>
              </ul>
            </div>
            <Link className="a-link" to="/Labelling">
              <ButtonComponent label={"Find More"} />
            </Link>
          </div>
        </div>
      </section>
      {/* <section className="service-section-2">
        <div className="row-2 row2nd">
          <div className="card">
            <div className="dropdown__icon">
              <i className="ri-home-5-fill"></i>
            </div>
            <h2>WareHousing</h2>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <div className="styles_divider__line"></div>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <div className="ul-element">
              <ul>
                <li>this is new</li>
                <li>this is new</li>
                <li>this is new</li>
              </ul>
            </div>
            <Link to="/warehousing">
              <ButtonComponent label={"Find More"} />
            </Link>
          </div>
          <div className="card">
            <div className="dropdown__icon">
              <i className="ri-price-tag-3-fill"></i>
            </div>
            <h2>Labelling</h2>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <div className="styles_divider__line"></div>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <div className="ul-element">
              <ul>
                <li>this is new</li>
                <li>this is new</li>
                <li>this is new</li>
              </ul>
            </div>

            <ButtonComponent label={"Find More"} />
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Service;
