export const KeyData = [
  {
    title: "Customs Compliance and Regulations",
    icon: "ri-draft-line",
    animation: "fade-right",
    content:
      "Proficient in navigating complex customs laws and regulations to ensure seamless and compliant international trade operations.",
  },
  {
    title: "Multi-Model Freight Solutions",
    icon: "ri-road-map-line",
    animation: "fade-left",
    content:
      "Expertise in managing freight across multiple modes of transportation, including air, sea, and land, to provide flexible and efficient shipping options.",
  },
  {
    title: "Advanced Warehousing Solutions",
    icon: "ri-community-line",
    animation: "fade-up",
    content:
      "Utilizing cutting-edge warehousing technology and practices to ensure safe, efficient, and scalable storage solutions.",
  },
  {
    title: "Integrated Supply Chain Management",
    icon: "ri-links-line",
    animation: "fade-right",
    content:
      "Holistic approach to managing the supply chain, integrating procurement, production, and distribution to optimize performance and reduce operational costs.",
  },
];

export const SeaFrieghtService = [
  {
    title: "Rate Analysis",
    icon: "ri-bar-chart-grouped-fill",
    content:
      "Harness the power of competitive rate analysis to unlock the best shipping options for your cargo",
    animation: "fade-right",
  },
  {
    title: "Carrier Selection and Booking",
    icon: "ri-bookmark-2-line",
    content:
      "Benefit from our extensive network of trusted carriers as we expertly navigate the selection and booking process on your behalf. With a focus on reliability and timeliness, we partner with industry-leading carriers to safeguard the integrity of your shipments from origin to destination.",
    animation: "fade-left",
  },
];

export const AirFrieghtService = [
  {
    title: "Airport-to-Airport Transfers",
    icon: "ri-plane-fill",
    content:
      "We facilitate efficient and dependable cargo transfers between airports, ensuring swift movement throughout the supply chain.",
    animation: "slide-right",
  },
  {
    title: "Door-to-Airport Delivery",
    icon: "ri-truck-line",
    content:
      "Enjoy the convenience of hassle-free pickups from your location and timely deliveries to the designated airport.",
    animation: "fade-left",
  },
  {
    title: "Freight Consolidation",
    icon: "ri-git-merge-line",
    content:
      "Our cost-effective consolidation services help optimize shipping expenses while ensuring the integrity of your cargo.",
    animation: "slide-up",
  },
  {
    title: "Flexible Service Options",
    icon: "ri-service-line",
    content:
      "Tailored solutions crafted to meet your unique business requirements, providing you with the flexibility you need to succeed.",
    animation: "fade-left",
  },
  {
    title: "Comprehensive Documentation Servicess",
    icon: "ri-todo-line",
    content:
      "Our expert team handles all essential paperwork, streamlining shipping processes and minimizing administrative burdens.",
    animation: "slide-right",
  },
];

export const DGShipping = [
  {
    title: "Expert Handling",
    icon: "ri-flask-line",
    animation: "fade-right",
    content:
      "With our specialized expertise in shipping dangerous goods, we ensure meticulous handling at every stage. Our team is trained to manage hazardous materials with precision, prioritizing safety and security.",
  },
  {
    title: "Regulatory Compliance",
    icon: "ri-book-open-line",
    animation: "fade-left",
    content:
      "We adhere strictly to DG shipping regulations, ensuring that all legal and safety requirements are met. Our comprehensive understanding of regulatory standards guarantees that your shipments are always compliant.",
  },
  {
    title: "Experienced Professionals",
    icon: "ri-p2p-fill",
    animation: "zoom-in-up",
    content:
      "Our seasoned professionals bring extensive knowledge and experience in DG shipping. They are equipped to handle complex logistics, ensuring the safe and efficient transport of your hazardous cargo.",
  },
  {
    title: "Reliable Transport",
    icon: "ri-bus-2-line",
    animation: "fade-up",
    content:
      "We provide reliable transport solutions, ensuring your hazardous materials reach their destination safely and on time. Our commitment to security and efficiency means you can trust us with your most sensitive shipments.",
  },
];
