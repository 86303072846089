import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/HomePages/Home/Home";
import Navbar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import OurStory from "./Components/Pages/OurStory/OurStory";
import SeaFrieght from "./Components/Pages/SeaFrieght/SeaFrieght";
import AirFrieght from "./Components/Pages/AirFrieght/AirFrieght";
import DGShipping from "./Components/Pages/DGShipping/DGShipping";
import WareHousing from "./Components/Pages/WareHousing/WareHousing";
import Labelling from "./Components/Pages/Labelling/Labelling";
import CustomClearencce from "./Components/Pages/CustomClearence/CustomClearence";
import ContactUs from "./Components/ContactUs/ContactUs";
import WhatsAppChat, {
  MessageChat,
} from "./Components/HomePages/WhatsAppShow/WhatsApp";
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <WhatsAppChat />
        <MessageChat />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/OurStory" element={<OurStory />} />
          <Route exact path="/Sea-Frieght" element={<SeaFrieght />} />
          <Route exact path="/Air-Frieght" element={<AirFrieght />} />
          <Route exact path="/DG-Shipping" element={<DGShipping />} />
          <Route exact path="/WareHousing" element={<WareHousing />} />
          <Route exact path="/Labelling" element={<Labelling />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route
            exact
            path="/Customs-Clearence"
            element={<CustomClearencce />}
          />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
