import React from "react";
import "./CustomFeature.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import {
  faComments,
  faFlag,
  faFileLines,
} from "@fortawesome/free-regular-svg-icons";
import ImgFeature from "../../../assets/6181570.jpg";

const CustomFeatures = () => {
  return (
    <>
      <div className="feat-content">
        <h5 className="headings" data-aos="fade-up">
          Custom Clearence
        </h5>
        <h2 data-aos="slide-up">Our Comprehensive Custom Clearance Services</h2>
        <p data-aos="fade-up">
          Experience seamless customs clearance services with us, where
          efficiency meets expertise. With decades of industry experience, we
          specialize in facilitating hassle-free customs clearance processes
          while upholding the highest standards of compliance with government
          regulations.
        </p>
      </div>

      <div className="Features">
        <div className="features-left">
          <div className="carding" data-aos="fade-left">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faFileLines} />
            </div>
            <div className="card-content">
              <h2>Documentation Handling</h2>
              <p>
                Leave the paperwork to us. Our proficient team manages all
                mandatory documentation with precision and accuracy, ensuring a
                smooth and streamlined clearance process.
              </p>
            </div>
          </div>
          <div className="carding" data-aos="fade-right">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faComments} />
            </div>
            <div className="card-content">
              <h2>Regulatory Compliance</h2>
              <p>
                Rest assured knowing that your shipments are in compliance with
                all customs regulations. We stay abreast of the latest changes
                and requirements to ensure seamless clearance for your cargo.
              </p>
            </div>
          </div>
          <div className="carding" data-aos="fade-left">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faFlag} />
            </div>
            <div className="card-content">
              <h2>Nationwide Services</h2>
              <p>
                Whether your cargo is crossing borders or traversing domestic
                territories, our nationwide network enables us to provide
                seamless processing and approvals across multiple locations.
              </p>
            </div>
          </div>
          <div className="carding" data-aos="fade-right">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faEquals} />
            </div>
            <div className="card-content">
              <h2>Transparency and Control</h2>
              <p>
                With our advanced tracking systems, you can monitor your
                shipment's progress in real time. Stay informed about your
                cargo's location and status, ensuring peace of mind and timely
                updates.
              </p>
            </div>
          </div>
        </div>
        <div className="features-right" data-aos="zoom-in-up">
          <img src={ImgFeature} alt="" />
        </div>
      </div>
    </>
  );
};

export default CustomFeatures;
