import React, { useEffect } from "react";
import { Link } from "react-scroll";
import "../WareHousing/WareHousing.css";
import WareHouseImg from "../../../assets/398.png";
import ForkLiftCard from "./ForkLiftCard";

const WareHousing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="WareHouse-container">
        <div className="WareHose-left" data-aos="slide-right">
          <img src={WareHouseImg} alt="Warehouse" className="left-image" />
        </div>
        <div className="WareHose-Right" data-aos="fade-up">
          <h5>Let’s shift your business</h5>
          <h3>Your Gateway to Seamless WareHousing Solutions</h3>
          <p>
            Embark on a journey of global trade with confidence, powered by
            Airsteam Logistics' comprehensive suite of WareHousing services.
          </p>
          <Link
            to="Ware_Housesection-2"
            smooth={true}
            offset={-180}
            duration={2000}
            href="#about-down"
          >
            <button
              className="btn-Home"
              data-aos="zoom-in-up"
              style={{ marginTop: "10%" }}
              type=""
            >
              Let's get started!
            </button>
          </Link>
        </div>
      </div>
      <div className="Ware_Housesection-2">
        <h6 data-aos="slide-up">Any Size With Affordable Price</h6>
        <p data-aos="fade-up">
          Logistics helps its entrepreneurs connect with mentors and associates
          in the global network through a targeted and needs-based approach.
          Connections include mentoring.
        </p>
      </div>
      <ForkLiftCard />
    </>
  );
};

export default WareHousing;
