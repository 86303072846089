import React, { useEffect } from "react";
import "../../Pages/OurStory/OurStory.css";
import OurStoryImg from "../../../assets/Group 15099.png";
import OurStoryImg2 from "../../../assets/Group 15101.png";
import KeyExpertise from "./KeyExpertise";
import MissionVision from "../MissionVision/MissionVision";

const OurStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="our-story-container">
        <div className="our-story-content">
          <h2>Our Story</h2>
        </div>
      </div>
      <MissionVision />
      <div className="whole-section">
        <section className="our-story-section">
          <div className="OurStory-Left">
            <h3 data-aos="fade-left">
              Leading the Way in Global Freight Solutions.
            </h3>
            <p data-aos="fade-up">
              Airsteam Logistics, headquartered in Delhi, India, stands at the
              forefront of international freight forwarding. Specializing in the
              seamless integration of supply chain, logistics, warehousing, and
              distribution services, we cater to diverse industries with
              precision and reliability. Our expansive network of branches
              across India ensures that we can meet your logistical needs with
              unmatched efficiency and care. Trust Airsteam Logistics to handle
              your cargo with expertise, providing comprehensive solutions that
              guarantee timely and secure deliveries worldwide. Experience the
              excellence in freight forwarding with Airsteam Logistics.
            </p>
          </div>
          <div className="OurStory-Right">
            <img
              data-aos="zoom-in-left"
              src={OurStoryImg}
              style={{ position: "relative", left: "6%" }}
              alt="Our Story"
            />
          </div>
        </section>
        <section className="our-story-section">
          <div className="OurStory-Right">
            <img src={OurStoryImg2} data-aos="zoom-in-right" alt="Our Story" />
          </div>
          <div className="OurStory-Left">
            <h3 data-aos="fade-right">Our Expertise.</h3>
            <p data-aos="fade-up">
              With a legacy of 16 years in freight forwarding and logistics, we
              stand as a beacon of reliability and excellence. We are proud to
              be an IATA-accredited agency and hold a Customs House Agent (CHA)
              license, and we maintain strong freight contracts with premier
              airlines and shipping lines.
            </p>
            <p data-aos="fade-up">
              Our extensive experience and industry certifications empower us to
              provide exceptional logistics solutions, ensuring your
              requirements are met with the highest standards of professionalism
              and efficiency
            </p>
          </div>
        </section>
      </div>
      <KeyExpertise />
    </>
  );
};

export default OurStory;
