import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../../assets/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IATA from "../../assets/IATA.png";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const socialLinks = [
  { icon: faFacebookF, url: "https://www.facebook.com/AirsteamLogistics" },
  { icon: faTwitter, url: "#" },
  { icon: faInstagram, url: "https://www.instagram.com/airsteamcochin/?hl=en" },
  { icon: faLinkedinIn, url: "#" },
];

const quickLinks = [
  { name: "Home", route: "/" },
  { name: "About", route: "/OurStory" },
  { name: "Services", route: "/services" },
  { name: "Contact", route: "/ContactUs" },
];
const services = [
  { name: "Sea Freight", route: "/Sea-Frieght" },
  { name: "Air Freight", route: "/Air-Frieght" },
  { name: "Warehousing", route: "/Warehousing" },
  { name: "Labelling", route: "/Labelling" },
  { name: "Customs Clearence", route: "/Custom-Clearence" },
];
const helpSupport = [
  { name: "Support Center", route: "/" },
  { name: "Live Chat", route: "/" },
  { name: "FAQ", route: "/" },
  { name: "Terms & Conditions", route: "/" },
];

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const textElement = document.querySelector(".typing-text");
    const textContent = textElement.textContent.trim();
    const typedText = textContent.split("");
    let animatedText = "";

    const typeAnimation = () => {
      typedText.forEach((char, index) => {
        const delay = index * 150;

        setTimeout(() => {
          animatedText += char;
          textElement.textContent = animatedText;
        }, 2000 + delay);
      });

      setTimeout(() => {
        animatedText = "";
        textElement.textContent = "";
        typeAnimation();
      }, 2000 + typedText.length * 150);
    };
    typeAnimation();
  }, []);

  return (
    <footer data-aos="fade-up">
      <div className="Footercontainer">
        <div className="wrapper">
          <div className="footer-widget">
            <Link to="/">
              <img src={logo} className="logo" alt="Logo" />
              <img src={IATA} className="iatalogo" alt="iata" />
            </Link>
            <p className="desc">
              "Large enough to handle any challenge with expertise, small enough
              to care about every detail personally."
            </p>
            <ul className="socials">
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.url}>
                    <FontAwesomeIcon icon={link.icon} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <FooterWidget title="Quick Links" links={quickLinks} />
          <FooterWidget title="Services" links={services} />
          <FooterWidget title="Help & Support" links={helpSupport} />
        </div>
        <div className="copyright-wrapper">
          <p>
            Copyright © 2021 Airsteam Logistics Design and Developed by
            <a
              href="https://invosys.co.in/"
              target="_blank"
              className="typing-text"
              rel="noopener noreferrer"
            >
              Invoking Systems Pvt.Ltd
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

const FooterWidget = ({ title, links }) => (
  <div className="footer-widget">
    <h6>{title}</h6>
    <ul className="links">
      {links.map((link, index) => (
        <li key={index}>
          <Link to={link.route}>{link.name}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default Footer;
