import React from "react";
import "./MissionVision.css";
import missionImage from "../../../assets/Mission.png";
import visionImage from "../../../assets/Vission.png";
import valuesImage from "../../../assets/Values.png";

const MissionVision = () => {
  return (
    <div className="mission-vision-container">
      <div className="mission-section">
        <div className="text mission-text" data-aos="fade-up">
          <h2>Our Mission</h2>
          <p>
            We are dedicated to understanding and delivering unparalleled
            logistics solutions to our clients. By leveraging our expertise and
            advanced technologies, we ensure seamless global connectivity,
            enabling efficient and reliable transportation across the world.
          </p>
          <p>
            Our commitment to excellence and customer satisfaction drives us to
            continuously innovate and optimize our services, making us a trusted
            partner in global logistics.
          </p>
        </div>
        <div className="VisonMissionimage">
          <img src={missionImage} data-aos="fade-left" alt="Mission" />
        </div>
      </div>
      <div className="vision-section">
        <div className="VisonMissionimage">
          <img src={visionImage} data-aos="fade-right" alt="Vision" />
        </div>
        <div className="text vision-text" data-aos="fade-up">
          <h2>Our Vision</h2>
          <p>
            Our vision is to emerge as the foremost global logistics provider,
            celebrated for our groundbreaking solutions, unparalleled customer
            service, and unwavering commitment to excellence.
          </p>
          <p>
            Through continuous innovation and a customer-centric approach, we
            aim to set new standards in the logistics industry, ensuring
            seamless and efficient supply chain solutions worldwide. Our
            dedication to reliability, sustainability, and technological
            advancement drives us to exceed expectations and empower our clients
            to thrive in a rapidly evolving global marketplace.
          </p>
        </div>
      </div>
      <div className="values-section">
        <div className="text values-text" data-aos="fade-up">
          <h2>Our Values</h2>
          <p>
            To revolutionize the logistics industry by providing exceptional,
            reliable, and innovative services that empower businesses and foster
            global connectivity. We aim to transform supply chains through
            cutting-edge technology and a deep understanding of our clients'
            needs.
          </p>
          <p>
            Our goal is to enhance efficiency, streamline operations, and create
            seamless logistical solutions that drive business growth and
            facilitate worldwide connections. By prioritizing excellence and
            continuous improvement, we strive to be a trusted partner in the
            global logistics landscape.
          </p>
        </div>
        <div className="VisonMissionimage" data-aos="fade-left">
          <img src={valuesImage} alt="Values" />
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
