import React from "react";
import "../WareHousing/ForkLiftCard.css";
import ForkLiftImage from "../../../assets/LabellingImg.png";
const LabellingCard = () => {
  return (
    <div className="logistics-container">
      <h1
        className="labelling-h1"
        data-aos="slide-up"
        style={{ marginTop: "8%" }}
      >
        Labelling Services
      </h1>
      <p data-aos="fade-up">
        Correctly labelling your cargo is of utmost importance. Beyond being a
        legal requirement, it serves as a crucial measure to mitigate risks
        associated with your goods. Labels play a vital role in communicating
        hazards linked with the package, while markings ensure safe handling to
        prevent spills, accidents, and exposure. Therefore, labels and markings
        must be applied accurately, contain the correct information, and adhere
        to regulations.
      </p>
      <p data-aos="fade-up">
        Labels should include the name and address of the shipper and consignee,
        display appropriate hazard and handling labels on the package, and
        feature the proper shipping name along with the UN or ID number. Our
        experienced staff is adept at labelling your goods correctly, ensuring
        they reach their designated destination promptly and safely.
      </p>
      <div className="logistics-content">
        <div className="right-side">
          <div className="logistics-items-rightside">
            <div className="logistics-item" data-aos="zoom-in-right">
              <div className="logistics-number">01</div>
              <div>
                <h2>High-Quality Labels</h2>
                <p>
                  Our labels are made from durable materials and printed with
                  advanced techniques to ensure they are both attractive and
                  long-lasting, suitable for any environmental condition.
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="zoom-in-left">
              <div className="logistics-number">02</div>
              <div>
                <h2>Custom Design Solutions</h2>
                <p>
                  We offer bespoke label designs that align with your brand
                  identity. Our creative team collaborates with you to develop
                  labels that effectively communicate your brand’s message and
                  aesthetic..
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="slide-up">
              <div className="logistics-number">03</div>
              <div>
                <h2>Fast and Reliable Turnaround</h2>
                <p>
                  We understand the importance of time. Our streamlined
                  production process guarantees quick turnaround times without
                  sacrificing quality, keeping you updated throughout.
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="zoom-in-left">
              <div className="logistics-number">04</div>
              <div>
                <h2>Competitive Pricing</h2>
                <p>
                  We offer discounts for bulk orders to provide you with the
                  best value.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="left-side">
          <div className="logistics-image" data-aos="zoom-in-right">
            <img src={ForkLiftImage} alt="Forklift" />
          </div>
          <div className="logistics-items">
            <div className="logistics-item" data-aos="fade-up">
              <div className="logistics-number">05</div>
              <div>
                <h2>Comprehensive Range of Services</h2>
                <p>
                  We provide a wide variety of labels, including product labels,
                  barcode and QR code labels, shipping and handling labels, and
                  promotional labels, catering to diverse industry needs.
                </p>
              </div>
            </div>
            <div className="logistics-item" data-aos="zoom-in-left">
              <div className="logistics-number">06</div>
              <div>
                <h2>Excellent Customer Support</h2>
                <p>
                  Our dedicated customer support team is available to assist you
                  from the initial consultation to the final delivery, ensuring
                  your satisfaction with our services and products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabellingCard;
